import React from 'react';
import Layout from '../layout';
import SEO from '../seo';
import Paginator from '../paginator';
import zoomButton from '../../assets/images/icons/zoom.svg'
import { SharePanel } from '../share-panel';

export default function ImageGalleryPage({pageContext}) {
    const data = pageContext;

    return (
        <Layout>
            <SEO title="Images" />
            <main>
                <div className="standard-container">
                    <h2 className="relative uk-text-center mt6 mb5">
                        Images
                        <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
                    </h2>
                    <div
                    className="uk-grid-small  uk-child-width-1-1 uk-child-width-1-4@s uk-text-center"
                    id="gallery-lightbox"
                    data-uk-grid
                    data-uk-lightbox="animation: slide">
                        {data.content.map(image => (
                            <div style={{display: 'inline-block', position: 'relative'}}>
                                <a href={`${image.media.publicURL}`}>
                                    <div className="animated-card animated-card-overlay-trigger">
                                        <div className="uk-inline w-100">
                                            <img src={`${image.media.publicURL}`} className="w-100" alt="" />
                                            <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                                                <img src={zoomButton} width="50px" height="50px" alt="play button" />
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="gallery-share-panel">
                                    <SharePanel media={image} type={'gallery'} lang={'en'} />
                                </div>
                            </div>
                        ))} 

                    </div>
                    <div style={{marginTop: '60px', display: 'flex', justifyContent: 'center'}}>
                        <Paginator totalPages={data.totalPages} pageNumber={data.pageNumber} prefix={'/gallery/images/'} />
                    </div>
                </div>
            </main>
        </Layout>
    );
}